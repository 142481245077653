body {
  margin: 0;
  color: #3b3e4f !important;
  font-family: 'Raleway', 'sans-serif' !important;
  .p-component {
    font-family: 'Raleway', 'sans-serif' !important;
  }
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.tour-details-body {
  flex-grow: 1;
}

.filter-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 9%; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

div a.p-lightbox-image-target img {
  height: 140px !important;
  width: 240px !important;
}

.p-lightbox-content {
  height: auto !important;
  width: 100% !important;
}

.p-lightbox-content-wrapper {
  width: 150% !important;
  margin-left: -25% !important;
}

.p-lightbox-content img {
  width: 100% !important;
}

body .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #ce1011 !important;
  background-color: #ce1011 !important;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #ce1011 !important;
}

body .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.5px #ce1011 !important;
  border-color: #ce1011 !important;
}

body .p-slider .p-slider-range {
  background-color: #ce1011 !important;
}

body .p-slider:not(.p-disabled) .p-slider-handle:hover {
  border: 2px solid #ce1011 !important;
}

body .p-datepicker table td > span {
  font-weight: 800;
}

body .p-disabled {
  opacity: 0.3;
}

body {
  margin: 0;
  color: #3b3e4f !important;
  margin: 0;
}

.foot-link {
  color: #333;
  display: block;
  text-decoration: none;
}

.foot-link:after {
  text-decoration: none;
}

@media screen and (min-width: 902px) {
  #mobile {
    display: none;
  }

  #pc {
    color: #ccc;
    z-index: 0;
  }

  #footer {
    margin-top: 120px;
  }

  .book-container {
    background-color: #ffffff;
    padding: 0 10px 0 10px;
    margin-bottom: 5px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 901px) {
  #pc {
    display: none;
  }
  #mobile {
    z-index: 0;
  }
  #footer {
    margin-top: 150px;
  }

  .book-container {
    background-color: #ffffff;
    padding: 0 10px 0 10px;
    margin-bottom: 55px;
    border-radius: 10px;
  }
}

body .p-slider:not(.p-disabled) .p-slider-handle:hover {
  border: 2px solid #ce1011 !important;
}

.full-width {
  width: 100%;
}

form {
  .p-inputtext,
  .p-calendar,
  .p-dropdown {
    width: 100%;
  }
}

p.subheading {
  font-size: 16px;
  padding: 5px 0;
  font-weight: 700;
}

.p-multiselect {
  label.p-multiselect-label {
    margin-bottom: 0;
  }
}

.readonly-form {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

$bg-color: #007ad9;
$bg-color-hover: #116fbf;

.rrt-confirm-holder .rrt-confirm {
  .rrt-buttons-holder {
    display: block;
    text-align: right;
    padding: 10px;
    .rrt-button {
      min-width: 80px;
      height: auto;
      display: inline-block;
      float: none;
      margin-right: 5px;
      background-color: $bg-color;
      padding: 5px 15px;
      color: #ffffff;
      border-radius: 4px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: $bg-color-hover;
        border-color: $bg-color-hover;
      }
      &:active {
        background-color: $bg-color-hover !important;
        border-color: $bg-color-hover !important;
      }
    }
  }
}

.p-calendar,
.p-multiselect,
.p-spinner,
.p-inputtext {
  width: 100% !important;
}

.carousel-inner {
  height: 400px;
  overflow: hidden;
}

.p-rating-icon {
  font-size: 30px !important;
}

.dropdown-menu {
  background-color: #343a40;
}

#searchHome * {
  border: 0 !important;
  background: #f5f5f5;
  font-size: 15px;
}

#searchHome i::before {
  font-size: 25px;
  color: #b7b7b7;
}

#searchHome ::placeholder {
  color: #b7b7b7;
}

#searchHome {
  margin-top: 50px;
  height: 40px;
  border-radius: 50%;
}

#searchHome .p-inputgroup-addon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#searchHome input {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.filterbox {
  border-radius: 7px;
  padding: 20px;
  position: relative;
  z-index: 10;
  background-color: #ffffff;
}

.horizontal-scroll {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.tour-card-featured {
  height: 400px;
  width: 260px;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  vertical-align: top;
  color: #fff;
  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  img {
    height: 400px;
    width: 260px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.pi-star {
  color: #ce1011 !important;
  font-size: 20px !important;
}

.pi-star-o {
  color: #ce1011 !important;
  font-size: 20px !important;
}

.tour-desc {
  position: absolute;
  top: 100%;
  transform: translateY(-100%);
  padding: 10px;
  width: 100%;
  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}
.tour-desc-background {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  opacity: 0.65;
  bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tour-name {
  margin-bottom: 0;
  font-size: 16px;
}

.tour-card {
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  border-radius: 10px;
  vertical-align: top;
  color: #fff;
  width: 265px;
  height: 200px;
  &:before {
    content: ' ';
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.section-header {
  color: #7f7f7f;
  a {
    color: #ce1011;
  }
}

.section {
  margin: 20px 0;
}

.fade-gray {
  // color: #7f7f7f;
  color: #000;
}

.fade-gray-description {
  color: #000;
  white-space: pre-line;
  font-size: 16px;
}

.label-text {
  color: #000;
}

.theme-red {
  color: #ce1011;
}

.image-header {
  height: 500px;
  width: 100%;
  object-fit: cover;
}

.order-header {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.tour-details-container {
  width: 100%;
  background-color: #fff;
  padding: 15px 30px;
}

.no-border-tabs {
  .p-tabview-title {
    color: #3b3e4f !important;
  }

  .p-tabview-panels {
    border: 0 !important;
    border-top: 1px solid #e5e5e5 !important;
    padding: 10px 0 !important;
  }
  .p-unselectable-text a {
    border: 0 !important;
    background-color: transparent !important;
  }

  a:not(.p-disabled):focus {
    box-shadow: 0 4px 2px -2px #ce1011 !important;
  }

  .p-highlight a {
    box-shadow: 0 4px 2px -2px #ce1011;
  }

  .p-tabview-nav {
    text-align: center !important;
    box-sizing: border-box;
    li {
      display: inline-block !important;
      // float: none !important;
      width: 49%;
    }
  }
}

.red-bullet {
  color: #ce1011;
  font-size: 30px;
  span {
    color: #3b3e4f;
    font-size: 14px;
  }
}

.icon {
  color: #ce1011;
  padding: 10px 30px;
  border: 1px solid #ce1011;
  border-radius: 50%;
  font-size: 20px;
}

.book-container {
  background-color: #ffffff;
  padding: 0 10px 0 10px;
  bottom: 10px;
  border-radius: 10px;
  transition: all ease-in-out 300ms;
}

.book-login {
  bottom: 10px;
  transition: all ease-in-out 300ms;
}

.bottom-reached {
  .book-container {
    bottom: 180px;
  }
  .book-login {
    bottom: 150px;
  }
}

.red-button {
  background-color: #ce1011 !important;
  border-radius: 10px;
  border: 1px solid #ce1011 !important;
}

.timeline .entry .title:before {
  color: #ce1011;
}

.booking {
  width: 100%;
  max-width: 800px;
  overflow-y: auto;
  .p-dialog-titlebar {
    background-color: #fff !important;
    height: 50px;
    border-radius: 10px 10px 0px 0px !important;
  }

  .p-datepicker,
  .p-dialog-footer {
    border: none !important;
    color: #3b3e4f !important;
  }
  .booking-footer {
    border: none !important;
    box-shadow: 0 -8px 5px -5px #ebebeb;
    position: relative;
    padding: 20px 50px 6px 50px !important;
    text-align: center !important;
  }
  .p-datepicker-month {
    border: none;
    font-size: 30px !important;
    font-weight: bold;
    margin-right: 50px !important;
  }
  .p-datepicker-year {
    border: none;
    color: #7f7f7f;
    font-size: 20px !important;
  }
  .p-dialog-content {
    border-top: 0px !important;
    border-radius: 0px 0px 10px 10px !important;
  }
}

@media only screen and (max-width: 600px) {
  .booking {
    height: 100%;
  }
}

.circle-icon {
  background-color: #ce1011;
  color: #fff;
  padding: 7px 13px;
  border-radius: 50%;
}

.next-button {
  width: 100% !important;
  background-color: #ce1011 !important;
  border: none !important;
  border-radius: 10px;
  padding: 13px 0 !important;
  color: #fff !important;
}

.prev-button {
  width: 100% !important;
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 10px;
  padding: 13px 0 !important;
  color: #7f7f7f !important;
}

.person-header {
  border-bottom: 1px solid #d8d8d8;
}

.book-header {
  padding: 10px 50px;
  label {
    font-size: 20px;
  }
}

.p-spinner-button {
  background-color: #ce1011 !important;
  border: none !important;
}

.fixed-bottom {
  z-index: 800;
}

.gray-form {
  .p-dropdown {
    min-width: 0 !important;
  }
  .p-dropdown,
  .p-inputtext,
  .p-dropdown-trigger,
  textarea,
  .form-control {
    background-color: #f8f8f9 !important;
  }
  .form-control {
    border: 1px solid #a6a6a6 !important;
  }
  a {
    color: #ce1011;
  }
  a:hover {
    color: #9c3839 !important;
  }
}

.message-box {
  background-color: #f8f8f9 !important;
  border: 1px solid #a6a6a6 !important;
  border-radius: 3px;
  padding: 10px;
  margin-top: 10px;
}

.p-togglebutton.p-highlight {
  background-color: #ce1011 !important;
}

.p-card {
  border-radius: 10px;
}

.comment-area {
  height: 40px;
}

.review-container {
  background-color: #ffffff;
  padding: 0 10px 0 10px;
  margin-bottom: 50px;
  border-radius: 10px;
  box-sizing: border-box !important;
}

#footer {
  background-color: #f8f9fa;
  padding: 10px 0;
  .navbar {
    position: initial;
  }
}

.hidden-filter {
  opacity: 0;
  padding: 0 15px;
  // top: 80px;
  top: 220px;
  left: 15px;
  width: 100%;
  position: absolute;
  transition: all ease-in-out 100ms;
}

.display-hidden-filter {
  opacity: 1;
}

.strikethrough {
  text-decoration: line-through;
}

div.tooltip {
  position: relative;

  /* positioning on the page */
  color: #eee;
  font-family: helvetica;
  text-decoration: none;
  top: 250px;
  text-shadow: 1px 1px 1px black;
}

div.tooltip::before {
  content: attr(data-tip);
  font-family: arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  z-index: 999;
  white-space: nowrap;
  bottom: 9999px;
  left: 11.5%;
  background: #00a4e4;
  color: #e0e0e0;
  padding: 7px;
  line-height: 24px;
  height: 24px;
  top: -55px;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-out;
  -moz-transition: opacity 0.4s ease-out;
  -o-transition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out;
  text-shadow: none;
}

div.tooltip:hover::before {
  opacity: 1;
  bottom: -35px;
}

.p-tooltip-text {
  color: #fff !important;
  background-color: #ce1011 !important;
}

.booking-form-body {
  max-height: 300px;
  overflow: hidden;
}

@import './custom';
