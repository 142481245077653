.viewer-img-item {
  height: 140px;
  display: inline-block;
  padding: 0 1.5px;
  cursor: pointer;
  img {
    height: 100%;
    width: 180px;
    object-fit: cover;
  }
}

.input-range {
  margin-top: 1rem !important;
  .input-range__track--background {
    height: 0.4em;
    background-color: #c8c8c8;
  }
  .input-range__track--active {
    background-color: #ce1011;
    height: 0.4em;
  }
  .input-range__slider {
    height: 1.143em;
    width: 1.143em;
    background-color: #ffffff;
    border: 2px solid #666666;
    border-radius: 100%;
    transition: box-shadow 0.2s;
    top: 50%;
    margin-top: -0.6715em;
  }
}

// ORDER SUMMARY
.order-summary {
  hr {
    margin-top: 13px;
    margin-bottom: 13px;
  }
  button {
    height: 40px;
    width: 185px;
    margin: 0 5px !important;
  }
  .with-outline {
    border: 2px solid #ce1011;
    color: #ce1011;
    background-color: #ffffff;
  }
}

.order-redirect {
  text-align: center;
  margin-top: 12px;
}

.total-container {
  display: flex;
  justify-content: start;
  .total {
    margin-right: 10px;
  }
}

.payment-tab {
  display: flex;
  justify-content: center;
  background-color: whitesmoke;
  align-items: center;
}
.paycard-tab {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-right: 150px !important;
  background-color: whitesmoke !important;
  border-radius: 0px !important;
  border: 0px !important;
  color: #333333 !important;
  font-weight: 700 !important;
}

.next-button {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.react-viewer-image {
  object-fit: cover;
}
.react-viewer-footer {
  img {
    object-fit: cover;
  }
}

/* ============================= auth form =============================== */

.auth-form {
  margin-top: 60px;
}

@media (min-width: 750px) {
  .auth-form {
    margin-top: 100px;
  }
}
